<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.id?'合并楼盘':'合并楼盘'"
    width="500px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-form
      ref="form"
      v-loading="contentLoading"
      :model="model"
      class="form"
      :label-width="$l('company.labelWidth','130px')">
      <!--      <el-form-item label="客户" prop="customerCompanyId" :rules="$rule.notNull">-->
      <!--        <vm-select-input :value="model.customerCompanyName" @select="$refs.customerSelect.open()"></vm-select-input>-->
      <!--      </el-form-item>-->
      <el-form-item label="楼盘名称" prop="name">
        <el-input v-model="model.name" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
      <el-form-item label="楼盘用途类型" prop="useType">
        <vm-dict-select v-model="model.useType" type="realEstateUseType"></vm-dict-select>
      </el-form-item>
      <el-form-item :label="$l('project.assigneeName','班组')" prop="assigneeId">
        <vm-autocomplete
          width="100%"
          :model.sync="model"
          :value-name="model.teamName"
          value-key="name"
          linkage-name="id"
          require-name="teamId"
          :value="{teamName:'name',teamId:'id'}"
          url="organization/team/list"></vm-autocomplete>
      </el-form-item>

      <el-form-item label="省市区" prop="districtCode" :rules="$rule.notNull">
        <vm-district :area-code.sync="model.districtCode"></vm-district>
      </el-form-item>

      <el-form-item label="经度" prop="longitude">
        <el-input v-model="model.longitude" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
      <el-form-item label="维度" prop="latitude">
        <el-input v-model="model.latitude" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel", "取消")}}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save", "保存")}}</el-button>
    </span>
    <customer-select ref="customerSelect" @select="selectCustomer"></customer-select>
  </el-dialog>
</template>
<script>
  import VmDistrict from "@/components/VmDistrictCascader";
  import CustomerSelect from "@/views/elevatorUsingCompany/CompanySelect";
  import VmAutocomplete from "@/components/VmAutocomplete";
  export default {
    components: {VmDistrict,CustomerSelect,VmAutocomplete},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        model: {
          id: 0,
          name: "",
          shortName: "",
          type: "",
          creditCode: "",
          contactName: "",
          contactPhone: "",
          signRadius:500,
          realEstateIds:[],
        },
      };
    },
    methods: {
      open(selectList) {
        const arr = [];
        selectList.forEach(item=>{
          arr.push(item.id);
        });
        this.model.realEstateIds=arr;
        this.dialogVisible = true;
        // this.getData();
      },
      getData() {
        if (this.model.id) {
          this.contentLoading = true;
          this.$http.get(`real-estate/${this.model.id}`).then(data => {
            this.contentLoading = false;
            this.model = data;
          });
        }
      },
      selectCustomer(row) {
        this.$set(this.model,"customerCompanyId",row.id);
        this.$set(this.model,"customerCompanyName",row.name);
        
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            this.$http
              .post("/real-estate/merge", this.model)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            this.$message.error("有不符合要求数据，请修改后提交");
          }
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>