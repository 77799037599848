<template>
  <el-dialog :visible.sync="visible" title="项目月报">
    <el-table v-loading="reportLoading" :data="reportList" :border="true">
      <el-table-column prop="createTime" label="生成日期" align="center">
        <template slot-scope="scope">
          <span>{{$moment(scope.row.createTime).format('YYYY-MM-DD')}}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate">
        <template slot-scope="scope">
          <el-button type="primary" @click="$refs.reportDetail.openDeatail(scope.row.id)">{{$l("common.detail", "查看")}}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <report-detail ref="reportDetail"></report-detail>
  </el-dialog>
</template>

<script>
  import ReportDetail from "@/views/elevatorUsingRealEstate/ReportDetail";

  export default {
    name: "ReportList",
    components:{ReportDetail},
    data() {
      return {
        reportLoading:false,
        visible:false,
        reportList:[],
      };
    },
    methods:{
      open(id) {
        this.$http.get("basic/project-operation-report/page",{realEstateId:id}).then(res=>{
          this.reportList = res.records;
          this.visible =true;
        });
      },
    },
  };
</script>

<style scoped>

</style>
