<template>
  <el-dialog
    v-el-drag-dialog
    destroy-on-close
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.id?$l('company.edit','编辑楼盘'):$l('company.add','新增楼盘')"
    width="900px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-form
      ref="form"
      v-loading="contentLoading"
      :model="model"
      class="form"
      :label-width="$l('company.labelWidth','130px')">
      <el-form-item label="楼盘名称" prop="name">
        <el-input v-model="model.name" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
      <el-form-item label="楼盘用途类型" prop="useType">
        <vm-dict-select v-model="model.useType" type="realEstateUseType"></vm-dict-select>
      </el-form-item>
      <el-form-item label="是否对接96333" prop="isTransfer">
        <el-select v-model="model.isTransfer" type="realEstateUseType">
          <el-option :value="true" label="是"></el-option>
          <el-option :value="false" label="否"></el-option>
        </el-select>
      </el-form-item>
      <!--      <el-form-item label="审批人" prop="roleIds">-->
      <!--        <el-select v-model="model.approveIds" multiple filterable placeholder="请选择">-->
      <!--          <el-option-->
      <!--            v-for="item in options"-->
      <!--            :key="item.id"-->
      <!--            :label="item.name"-->
      <!--            :value="item.id"></el-option>-->
      <!--        </el-select>-->
      <!--      </el-form-item>-->

      <el-tag class="tag">维保班组</el-tag> <el-button size="small" type="text" @click="$refs.teamSelect.open()">选择班组</el-button>
      <el-table
        :data="model.teams"
        label-position="left"
        class="demo-table-expand"
        style="margin-bottom: 4px"
      >
        <el-table-column prop="name" label="班组名称" align="center" width="150"></el-table-column>
        <el-table-column prop="typeDesc" label="班组类型" align="center"></el-table-column>
        <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate" width="100">
          <template slot-scope="scope">
            <el-button type="danger" @click="deleteLine(scope.row)">{{$l("common.delete", "删除")}}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-form-item label="省市区" prop="districtCode">
        <vm-district :area-code.sync="model.districtCode"></vm-district>
      </el-form-item>
      <el-form-item :label="$l('project.name','使用单位')" prop="name">
        <vm-autocomplete

          url="customer-relationship/customer-company/list"
          :model.sync="model"
          :value-name="model.customerCompanyName"
          :value="{customerCompanyName:'name',customerCompanyId:'id'}"
          width="100%"
        >
        </vm-autocomplete
        >
      </el-form-item>
      <div></div>
      <el-form-item label="详细地址" prop="detailAddress">
        <el-input v-model="model.detailAddress" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>

      <el-form-item label="经度" prop="longitude">
        <el-input v-model="model.longitude" disabled :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
      <el-form-item label="维度" prop="latitude">
        <el-input v-model="model.latitude" disabled :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
      <el-form-item label="签到半径" prop="signRadius">
        <el-input-number v-model="model.signRadius" :min="1" controls-position="right" :placeholder="$l('common.enter','请输入')"></el-input-number>
      </el-form-item>
      <el-form-item label="保养周期" prop="serviceInterval">
        <el-input-number v-model="model.serviceInterval" :min="0" :placeholder="$l('common.enter','请输入')"></el-input-number>
      </el-form-item>
      <span v-if="dialogVisible">
        <vm-point-map

          ref="map"
          :lat.sync="model.latitude"
          :lng.sync="model.longitude"
          :coordinates="{lat:model.latitude,lng:model.longitude}"
          :sign-radius.sync="model.signRadius"
          :only-one="true"></vm-point-map>
      </span>
    </el-form>


    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel", "取消")}}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save", "保存")}}</el-button>
    </span>
    <teamSelect ref="teamSelect" :checkbox="true" @select="teamSelect"></teamSelect>
  </el-dialog>
</template>
<script>
  import VmDistrict from "@/components/VmDistrictCascader";
  import VmPointMap from "@/components/VmPointMap";
  import teamSelect from "@/views/framework/teamSelect";
  import VmAutocomplete from "@/components/VmAutocomplete";
  export default {
    components: {VmDistrict,VmPointMap,teamSelect,VmAutocomplete},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        filter:{},
        model: {
          id: 0,
          name: "",
          detailAddress: "",
          shortName: "",
          type: "",
          creditCode: "",
          contactName: "",
          contactPhone: "",
          lat:"",
          lng:"",
          signRadius:500,
          teams:[],
          serviceInterval:0,
          // approveIds:[],
        },
        options:[],
      };
    },
    methods: {
      open(id) {
        this.model.id = id;
        this.getData();
        this.getUserList();
      },
      getData() {
        if (this.model.id) {
          this.contentLoading = true;
          this.$http.get(`real-estate/${this.model.id}`).then(data => {
            this.contentLoading = false;
            // if (data.approves) {
            //   data.approveIds =data.approves.map(itm=>{return itm.id;});
            // }
            // console.log(data,149);
            this.model = data;
            if (!data.teams) {
              this.model.teams=[];
            }
            // if (data.approves) {
            //   this.model.approveIds =data.approves.map(item=>{
            //     return item.id;
            //   });
            // }
            this.dialogVisible = true;
          });

        }else {
          this.dialogVisible = true;
        }
      },
      getUserList() {
        this.$http.get("/organization/employee/list").then(data=>{
          this.options= data;
          // console.log(data,157);
        });
      },
      selectCustomer(row) {
        this.$set(this.model,"developerCompanyId",row.id);
        this.$set(this.model,"developerCompanyName",row.name);

      },
      teamSelect(row) {
        if (row instanceof Array) {
          row.forEach(item=>{
            if (!this.model.teams.some(v=>v.id===item.id)) {
              this.model.teams.push(({...item,teamId:item.id}));
            }
          });
        }else {
          console.log(row,220);
          if (this.model.teams.some(v=>v.id===row.id)) {
            this.$message.info("重复添加");
          }else {
            this.model.teams.push({...row,teamId:row.id});
          }
        }

      },
      deleteLine(row) {
        this.model.teams=this.model.teams.filter(v=>v.id!==row.id);
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            const arr =this.model.teams.map(item=>item.id);
            this.$http
              .save("real-estate/", {...this.model,teamIds:arr})
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            this.$message.error("有不符合要求数据，请修改后提交");
          }
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
